import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyAccordionContent, LazyAccordionsWithSideImage, LazyAnalyticsBanner, LazyAnalyticsNavigatorSection, LazyAnalyticsSubNav, LazyBaseSection, LazyBaseTextWithIcon, LazyBlockquoteSection, LazyBlockquoteSlider, LazyBlog, LazyBlogSlider, LazyCardWrapper, LazyConsultingProcess, LazyConsultingStep, LazyContactCta, LazyContainer, LazyCta, LazyCtaGrid, LazyDialog, LazyDivWrap, LazyDynamicForm, LazyFaqReference, LazyFaqSection, LazyFeature, LazyFeature2Cols, LazyFeatureGridSmall, LazyFeaturesNumberGrid, LazyFollowBox, LazyFounderAvatar, LazyGlossar, LazyGlossarList, LazyGrid4x4, LazyGrid, LazyGuidingPrinciple, LazyHeroHeader, LazyImage, LazyImageVideo, LazyInputField, LazyKeyvisual, LazyKeyvisualCustomColorWithImage, LazyLinkLink, LazyList, LazyListItemWithIcon2, LazyLottie, LazyMainHero, LazyMediaContentLibrary, LazyMediaTopItems, LazyMenuLink, LazyNewsletterSubscribeLink, LazyNumberStepper, LazyPage, LazyPageHome, LazyPricingPlan, LazyProduct, LazyProductTeaser, LazyProductTeasers, LazyReferenceLogos, LazySalesContactForm, LazySalesContactLink, LazyScrubbableVideo, LazyServicesBanner, LazyShop, LazyShopItem, LazySilia, LazySlider, LazySolutionStudies, LazySolutionStudiesCopy, LazyStepper, LazySuccessStoriesCta, LazySuccessStory, LazySuccessStoryCard, LazySuccessStoryCards, LazySuccessStoryGrid, LazySuccessStorySlider, LazyTabContent, LazyTeaser, LazyText, LazyTextIconCard, LazyTextNoSection, LazyTextSection, LazyTextWithColumns, LazyTextWithIcon, LazyUseCase, LazyUseCaseHomeSlider, LazyUseCaseSlider, LazyUseCaseTabs, LazyUseCaseTeasers, LazyVideoBackground, LazyWallOfLove, LazyWrapper, LazyNuxtScrollbar } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["AccordionContent", LazyAccordionContent],
["AccordionsWithSideImage", LazyAccordionsWithSideImage],
["AnalyticsBanner", LazyAnalyticsBanner],
["AnalyticsNavigatorSection", LazyAnalyticsNavigatorSection],
["AnalyticsSubNav", LazyAnalyticsSubNav],
["BaseSection", LazyBaseSection],
["BaseTextWithIcon", LazyBaseTextWithIcon],
["BlockquoteSection", LazyBlockquoteSection],
["BlockquoteSlider", LazyBlockquoteSlider],
["Blog", LazyBlog],
["BlogSlider", LazyBlogSlider],
["CardWrapper", LazyCardWrapper],
["ConsultingProcess", LazyConsultingProcess],
["ConsultingStep", LazyConsultingStep],
["ContactCta", LazyContactCta],
["Container", LazyContainer],
["Cta", LazyCta],
["CtaGrid", LazyCtaGrid],
["Dialog", LazyDialog],
["DivWrap", LazyDivWrap],
["DynamicForm", LazyDynamicForm],
["FaqReference", LazyFaqReference],
["FaqSection", LazyFaqSection],
["Feature", LazyFeature],
["Feature2Cols", LazyFeature2Cols],
["FeatureGridSmall", LazyFeatureGridSmall],
["FeaturesNumberGrid", LazyFeaturesNumberGrid],
["FollowBox", LazyFollowBox],
["FounderAvatar", LazyFounderAvatar],
["Glossar", LazyGlossar],
["GlossarList", LazyGlossarList],
["Grid4x4", LazyGrid4x4],
["Grid", LazyGrid],
["GuidingPrinciple", LazyGuidingPrinciple],
["HeroHeader", LazyHeroHeader],
["Image", LazyImage],
["ImageVideo", LazyImageVideo],
["InputField", LazyInputField],
["Keyvisual", LazyKeyvisual],
["KeyvisualCustomColorWithImage", LazyKeyvisualCustomColorWithImage],
["LinkLink", LazyLinkLink],
["List", LazyList],
["ListItemWithIcon2", LazyListItemWithIcon2],
["Lottie", LazyLottie],
["MainHero", LazyMainHero],
["MediaContentLibrary", LazyMediaContentLibrary],
["MediaTopItems", LazyMediaTopItems],
["MenuLink", LazyMenuLink],
["NewsletterSubscribeLink", LazyNewsletterSubscribeLink],
["NumberStepper", LazyNumberStepper],
["Page", LazyPage],
["PageHome", LazyPageHome],
["PricingPlan", LazyPricingPlan],
["Product", LazyProduct],
["ProductTeaser", LazyProductTeaser],
["ProductTeasers", LazyProductTeasers],
["ReferenceLogos", LazyReferenceLogos],
["SalesContactForm", LazySalesContactForm],
["SalesContactLink", LazySalesContactLink],
["ScrubbableVideo", LazyScrubbableVideo],
["ServicesBanner", LazyServicesBanner],
["Shop", LazyShop],
["ShopItem", LazyShopItem],
["Silia", LazySilia],
["Slider", LazySlider],
["SolutionStudies", LazySolutionStudies],
["SolutionStudiesCopy", LazySolutionStudiesCopy],
["Stepper", LazyStepper],
["SuccessStoriesCta", LazySuccessStoriesCta],
["SuccessStory", LazySuccessStory],
["SuccessStoryCard", LazySuccessStoryCard],
["SuccessStoryCards", LazySuccessStoryCards],
["SuccessStoryGrid", LazySuccessStoryGrid],
["SuccessStorySlider", LazySuccessStorySlider],
["TabContent", LazyTabContent],
["Teaser", LazyTeaser],
["Text", LazyText],
["TextIconCard", LazyTextIconCard],
["TextNoSection", LazyTextNoSection],
["TextSection", LazyTextSection],
["TextWithColumns", LazyTextWithColumns],
["TextWithIcon", LazyTextWithIcon],
["UseCase", LazyUseCase],
["UseCaseHomeSlider", LazyUseCaseHomeSlider],
["UseCaseSlider", LazyUseCaseSlider],
["UseCaseTabs", LazyUseCaseTabs],
["UseCaseTeasers", LazyUseCaseTeasers],
["VideoBackground", LazyVideoBackground],
["WallOfLove", LazyWallOfLove],
["Wrapper", LazyWrapper],
["NuxtScrollbar", LazyNuxtScrollbar],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
