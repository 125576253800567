export const appHead = {"meta":[{"charset":"utf-8"},{"hid":"base","name":"base","content":"https://zweikern.com"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"},{"hid":"robots","name":"robots","content":"index, follow"},{"name":"Handheldfriendly","content":"true"}],"link":[{"rel":"apple-touch-icon","size":"144x144","href":"/favicons/apple-touch-icon.png"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","size":"32x32","href":"/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","size":"16x16","href":"/favicons/favicon-16x16.png"},{"rel":"manifest","href":"/favicons/site.webmanifest"},{"rel":"mask-icon","href":"/favicons/safari-pinned-tab.svg","color":"#ad0083"}],"style":[],"script":[{"hid":"cookiebar","src":"/consentmanager.js","defer":true,"type":"text/javascript"},{"hid":"gtmHead","innerHTML":"\n          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','GTM-TZR3Z5K');"},{"hid":"srollMagic","src":"/scrollmagic/scrollmagic/minified/ScrollMagic.min.js","defer":true,"type":"text/javascript"}],"noscript":[],"titleTemplate":"%s | zweikern","title":"zweikern"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false